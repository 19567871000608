// AuthProvider.tsx
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { baseApiUrl } from "../Utilities/constants";
import { Flex, Spinner } from "@chakra-ui/react";

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface User {
    email: string;
    id: string;
}

export interface IAuth {
    authStatus: AuthStatus;
    user: User | null;
    signIn: (email: string, password: string) => Promise<void>;
    signOut: () => void;
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    signUp: (email: string, password: string, confirmPassword: string) => Promise<void>;
    isLoggedIn: () => boolean;
}

const defaultState: IAuth = {
    authStatus: AuthStatus.Loading,
    user: null,
    signIn: async () => { },
    signUp: async () => { },
    changePassword: async () => { },
    signOut: () => { },
    isLoggedIn: () => false,
};

type Props = {
    children?: React.ReactNode;
};

export const AuthContext = createContext<IAuth>(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
    const { authStatus } = useContext(AuthContext);
    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
    const { authStatus } = useContext(AuthContext);
    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const userStorageKey = "current_user";

const AuthProvider = ({ children }: Props) => {
    // Initialize user from localStorage synchronously
    const [user, setUser] = useState<User | null>(() => {
        const storedUser = localStorage.getItem(userStorageKey);
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [authStatus, setAuthStatus] = useState<AuthStatus>(() => {
        return user ? AuthStatus.SignedIn : AuthStatus.Loading;
    });

    const storeUserData = useCallback((userData: User) => {
        localStorage.setItem(userStorageKey, JSON.stringify(userData));
        setUser(userData);
        setAuthStatus(AuthStatus.SignedIn);
    }, []);

    const clearUserData = useCallback(() => {
        localStorage.removeItem(userStorageKey);
        setUser(null);
        setAuthStatus(AuthStatus.SignedOut);
    }, []);

    const handleLogout = useCallback(async (callApi = true) => {
        try {
            if (callApi) {
                await axios.post(
                    `${baseApiUrl}/auth/logout`,
                    {},
                    {
                        withCredentials: true,
                        validateStatus: () => true, // Always resolve
                    }
                );
            }
        } catch (error) {
            console.error("Logout error:", error);
        } finally {
            clearUserData();
        }
    }, [clearUserData]);

    // Setup response interceptor
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.config?.url.includes('/auth')) {
                    return Promise.reject(error);
                }

                if (error.response?.status === 401) {
                    handleLogout();
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [handleLogout]);

    // Initial session check with empty dependency array
    useEffect(() => {
        const verifySession = async () => {
            try {
                const response = await axios.get(`${baseApiUrl}/auth/me`, {
                    withCredentials: true,
                    validateStatus: (status) => status < 500,
                });

                if (response.status === 200 && response.data.account) {
                    // Only update if user data differs to prevent unnecessary state updates
                    if (JSON.stringify(user) !== JSON.stringify(response.data.account)) {
                        storeUserData(response.data.account);
                    }
                } else {
                    handleLogout(false);
                }
            } catch (error) {
                handleLogout(false);
            }
        };

        verifySession();

        // Empty dependency array ensures this runs only once on mount
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const signIn = useCallback(async (email: string, password: string) => {
        try {
            setAuthStatus(AuthStatus.Loading);
            const response = await axios.post(
                `${baseApiUrl}/auth/login`,
                { email, password },
                { withCredentials: true }
            );

            if (response.status === 200 && response.data.account) {
                storeUserData(response.data.account);
            } else {
                throw new Error("Invalid login response");
            }
        } catch (error) {
            console.error("Login failed:", error);
            handleLogout(false);
            throw error; // Optional: Re-throw to handle in UI
        }
    }, [storeUserData, handleLogout]);

    const signUp = useCallback(async (email: string, password: string, confirmPassword: string) => {
        try {
            if (password !== confirmPassword) {
                throw new Error("Passwords do not match");
            }

            setAuthStatus(AuthStatus.Loading);
            const response = await axios.post(
                `${baseApiUrl}/auth/create-account`,
                { email, password },
                { withCredentials: true }
            );

            if (response.status === 201) {
                // Assuming the API automatically logs in the user upon signup
                // If not, you might need to call signIn here
                await handleLogout(false); // Clear existing data
                await signIn(email, password); // Sign in after signup
            } else {
                throw new Error("Signup failed");
            }
        } catch (error) {
            console.error("Signup failed:", error);
            handleLogout(false);
            throw error; // Optional: Re-throw to handle in UI
        }
    }, [handleLogout, signIn]);

    const changePassword = useCallback(async (oldPassword: string, newPassword: string) => {
        try {
            await axios.post(
                `${baseApiUrl}/auth/change-password`,
                { old_password: oldPassword, new_password: newPassword },
                { withCredentials: true }
            );
        } catch (error) {
            console.error("Change password failed:", error);
            throw error; // Re-throw for error handling in components
        }
    }, []);

    const isLoggedIn = useCallback(() => authStatus === AuthStatus.SignedIn, [authStatus]);

    const signOut = useCallback(() => {
        handleLogout(true);
    }, [handleLogout]);

    const state: IAuth = {
        authStatus,
        user,
        signIn,
        signUp,
        changePassword,
        signOut,
        isLoggedIn,
    };

    return (
        <AuthContext.Provider value={state}>
            <div>
                {authStatus === AuthStatus.Loading ? (
                    <Flex justifyContent="center" alignItems="center" position="fixed" top={'65px'} left={0} right={0} bottom={0} bg="rgba(255, 255, 255, 0.025)" zIndex={9999}>
                        <Spinner size={'xl'} />
                    </Flex>
                ) : null}
                {children}
            </div>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
