// AnalyticsFilters.tsx

import React from 'react';
import {
    Button,
    Checkbox,
    Flex,
    HStack,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    Spinner,
} from '@chakra-ui/react';
import { MetadataResponse } from '../Contexts/AppStateContext';

interface AnalyticsFiltersProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    metadataResponse: MetadataResponse | null;
    filterState: Record<string, (string | number)[]>;
    setFilterState: React.Dispatch<React.SetStateAction<Record<string, (string | number)[]>>>;
    metadataLoading: boolean;
}

/**
 * Convert a camelCase or mixed-case string into a more readable Title Case string.
 */
function convertCamelCaseToTitleCase(str: string): string {
    if (!str) return str;
    let withSpaces = str.replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2');
    withSpaces = withSpaces.replace(/([a-z\d])([A-Z])/g, '$1 $2');
    const tokens = withSpaces.split(' ');
    const finalTokens = tokens.map((token) => {
        if (token === token.toUpperCase()) {
            return token;
        }
        return token.charAt(0).toUpperCase() + token.slice(1).toLowerCase();
    });
    return finalTokens.join(' ');
}

const AnalyticsFilters: React.FC<AnalyticsFiltersProps> = ({
    isOpen,
    onClose,
    onOpen,
    metadataResponse,
    filterState,
    setFilterState,
    metadataLoading,
}) => {
    // Flag to control whether to show the "eventData" category.
    const showEventData = false;

    // Determine whether metadata exists (i.e. the metadata object is not empty)
    const hasMetadata = metadataResponse && Object.keys(metadataResponse.metadata).length > 0;

    // Compute total selected filters (excluding eventData if not shown). If there is no metadata, display no number.
    const totalSelected = hasMetadata
        ? Object.entries(filterState).reduce((acc, [key, values]) => {
            if (key === 'eventData' && !showEventData) return acc;
            return acc + values.length;
        }, 0)
        : 0;

    // Function to clear all selected filters.
    const clearFilters = () => {
        setFilterState((prev) => {
            const newState = { ...prev };
            Object.keys(newState).forEach((key) => {
                newState[key] = [];
            });
            return newState;
        });
    };

    return (
        <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end" closeOnBlur>
            <PopoverAnchor>
                <Button variant="outline" onClick={onOpen}>
                    Filters{totalSelected > 0 ? ` (${totalSelected})` : ''}
                </Button>
            </PopoverAnchor>

            <PopoverContent minW="800px" maxW="90vw" maxH="80vh" overflowY="auto">
                <PopoverCloseButton />
                <PopoverHeader>Select Filters</PopoverHeader>
                <PopoverBody>
                    {metadataLoading ? (
                        <Flex justifyContent="center" alignItems="center" minH="200px">
                            <Spinner />
                        </Flex>
                    ) : metadataResponse ? (
                        // If there is no metadata for this range, display a message.
                        !hasMetadata ? (
                            <Text>No metadata for this range.</Text>
                        ) : (
                            <>
                                <Tabs orientation="vertical" variant="line" isFitted={false}>
                                    <HStack align="start" spacing={4} w="100%">
                                        <TabList
                                            minWidth="220px"
                                            maxWidth="220px"
                                            borderRightWidth="1px"
                                            fontSize="sm"
                                            overflow="hidden"
                                        >
                                            {showEventData && (
                                                <Tab
                                                    justifyContent="flex-start"
                                                    textAlign="start"
                                                    _hover={{ bg: 'gray.50', _dark: { bg: 'whiteAlpha.100' } }}
                                                    _selected={{
                                                        bg: 'gray.100',
                                                        _dark: { bg: 'whiteAlpha.200' },
                                                        color: 'blue.600',
                                                    }}
                                                >
                                                    {'Event Name' +
                                                        (filterState['eventData'] && filterState['eventData'].length > 0
                                                            ? ` (${filterState['eventData'].length})`
                                                            : '')}
                                                </Tab>
                                            )}
                                            {Object.keys(metadataResponse.metadata).map((key) => (
                                                <Tab
                                                    key={key}
                                                    justifyContent="flex-start"
                                                    textAlign="start"
                                                    _hover={{ bg: 'gray.50', _dark: { bg: 'whiteAlpha.100' } }}
                                                    _selected={{
                                                        bg: 'gray.100',
                                                        _dark: { bg: 'whiteAlpha.200' },
                                                        color: 'blue.600',
                                                    }}
                                                >
                                                    {convertCamelCaseToTitleCase(key) +
                                                        (filterState[key] && filterState[key].length > 0 ? ` (${filterState[key].length})` : '')}
                                                </Tab>
                                            ))}
                                        </TabList>

                                        <TabPanels flex="1">
                                            {showEventData && (
                                                <TabPanel>
                                                    <VStack align="start" spacing={2}>
                                                        <Checkbox
                                                            isChecked={
                                                                filterState['eventData'] &&
                                                                filterState['eventData'].length === metadataResponse.eventData.length &&
                                                                metadataResponse.eventData.length > 0
                                                            }
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setFilterState((prev) => ({
                                                                    ...prev,
                                                                    eventData: isChecked ? [...metadataResponse.eventData] : [],
                                                                }));
                                                            }}
                                                        >
                                                            Select All
                                                        </Checkbox>
                                                        <Flex wrap="wrap" gap={4}>
                                                            {metadataResponse.eventData.map((value) => (
                                                                <Checkbox
                                                                    key={value}
                                                                    spacing="1rem"
                                                                    isChecked={filterState['eventData']?.includes(value) || false}
                                                                    onChange={(e) => {
                                                                        setFilterState((prev) => {
                                                                            const isChecked = e.target.checked;
                                                                            const currentArr = prev['eventData'] || [];
                                                                            let newArr;
                                                                            if (isChecked) {
                                                                                newArr = [...currentArr, value];
                                                                            } else {
                                                                                newArr = currentArr.filter((v) => v !== value);
                                                                            }
                                                                            return {
                                                                                ...prev,
                                                                                eventData: newArr,
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                    {value}
                                                                </Checkbox>
                                                            ))}
                                                        </Flex>
                                                    </VStack>
                                                </TabPanel>
                                            )}

                                            {Object.entries(metadataResponse.metadata).map(([key, values]) => (
                                                <TabPanel key={key}>
                                                    <VStack align="start" spacing={2}>
                                                        <Checkbox
                                                            isChecked={
                                                                filterState[key] &&
                                                                filterState[key].length === values.length &&
                                                                values.length > 0
                                                            }
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setFilterState((prev) => ({
                                                                    ...prev,
                                                                    [key]: isChecked ? [...values] : [],
                                                                }));
                                                            }}
                                                        >
                                                            Select All
                                                        </Checkbox>
                                                        <Flex wrap="wrap" gap={4}>
                                                            {values.map((val) => (
                                                                <Checkbox
                                                                    key={val}
                                                                    spacing="1rem"
                                                                    isChecked={filterState[key]?.includes(val) || false}
                                                                    onChange={(e) => {
                                                                        setFilterState((prev) => {
                                                                            const isChecked = e.target.checked;
                                                                            const currentArr = prev[key] || [];
                                                                            let newArr;
                                                                            if (isChecked) {
                                                                                newArr = [...currentArr, val];
                                                                            } else {
                                                                                newArr = currentArr.filter((v) => v !== val);
                                                                            }
                                                                            return {
                                                                                ...prev,
                                                                                [key]: newArr,
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                    {val}
                                                                </Checkbox>
                                                            ))}
                                                        </Flex>
                                                    </VStack>
                                                </TabPanel>
                                            ))}
                                        </TabPanels>
                                    </HStack>
                                </Tabs>
                                {/* Clear Button */}
                                <Flex mt={4} justifyContent="flex-end">
                                    <Button variant="outline" size="sm" onClick={clearFilters}>
                                        Clear
                                    </Button>
                                </Flex>
                            </>
                        )
                    ) : (
                        <Text>No metadata available.</Text>
                    )}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default AnalyticsFilters;
