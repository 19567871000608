// AppStateProvider.tsx
import React, { useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { AuthContext, AuthStatus } from "../Contexts/AuthContext"; // Ensure correct import path
import { baseApiUrl } from "../Utilities/constants";

export interface AnalyticsLog {
    ServiceKey: string;
    UserID: string;
    SessionID: string;
    Action: string;
    Payload: any;
    Platform: string;
    Timestamp: string;
}

export interface DailyMetric {
    ActivityDate: string;
    UniqueUsers: number;
    Sessions: number;
    MedianSessionTime: number;
    TotalTimeSpent: number;
    PeakConcurrent: number;
    DailyNewUsers: number;
}

export interface RetentionMetric {
    CohortDate: string;
    DayOffset: number;
    RetainedUsers: number;
    CohortSize: number;
    RetentionRate: number;
}

export interface OverviewData {
    concurrent_live: number;
    daily_metrics: DailyMetric[];
    median_session_time: number;
    median_time_per_day: number;
    median_time_per_user: number;
    peak_concurrent: number;
    peak_dau: number;
    retention_metrics: RetentionMetric[];
    sessions_per_user: number;
    total_sessions: number;
    total_time_spent: number;
    unique_users: number;
    weighted_avg_retention: number;
    overall_new_users: number;
}

export interface Application {
    service_key: string;
    account_id: string;
    name: string;
    platform: string;
    steam_url: string | null;
    created_at: string;
}

// The response for metadata. The 'metadata' object can contain arrays of strings or numbers.
export interface MetadataResponse {
    eventData: string[];
    metadata: Record<string, (string | number)[]>;
}

export interface IAppState {
    applications: Application[] | null;
    selectedApplication: Application | null;
    storedOverviewData: { [service_key: string]: OverviewData } | null;
    loadAllApplications: () => Promise<void>;
    createApplication: (name: string, platform: string, steam_url?: string) => Promise<void>;
    deleteApplication: (service_key: string) => Promise<void>;
    selectApplication: (application: Application) => void;
    addAccountToApplication: (service_key: string, account_id: string) => Promise<void>;
    getOverviewData: (service_key: string, start_date: string, end_date: string, filters?: Record<string, (string | number)[]>) => Promise<OverviewData>;
    getLiveFeedData: (service_key: string) => Promise<AnalyticsLog[]>;
    getAllAddedAccounts: (service_key: string) => Promise<string[]>;
    removeAccountFromApplication: (service_key: string, account_id: string) => Promise<void>;

    // NEW: fetch metadata
    getMetadata: (service_key: string, start_date: string, end_date: string, get_full_event_data?: boolean) => Promise<MetadataResponse>;
}

const defaultState: IAppState = {
    applications: null,
    selectedApplication: null,
    storedOverviewData: null,
    loadAllApplications: async () => { },
    createApplication: async () => { },
    deleteApplication: async () => { },
    selectApplication: () => { },
    addAccountToApplication: async () => { },
    getOverviewData: async () => ({
        concurrent_live: 0,
        daily_metrics: [],
        median_session_time: 0,
        median_time_per_day: 0,
        median_time_per_user: 0,
        peak_concurrent: 0,
        peak_dau: 0,
        retention_metrics: [],
        sessions_per_user: 0,
        total_sessions: 0,
        total_time_spent: 0,
        unique_users: 0,
        weighted_avg_retention: 0,
        overall_new_users: 0
    }),
    getLiveFeedData: async () => [],
    getAllAddedAccounts: async () => [],
    removeAccountFromApplication: async () => { },

    // Default for getMetadata
    getMetadata: async () => ({
        eventData: [],
        metadata: {}
    }),
};

type Props = {
    children?: React.ReactNode;
};

export const AppStateContext = React.createContext<IAppState>(defaultState);

const applicationsStorageKey = "applications";
const selectedApplicationStorageKey = "selected_application";
const storedOverviewDataKey = "stored_overview_data";

const AppStateProvider = ({ children }: Props) => {
    const { authStatus } = useContext(AuthContext);
    /*const [applications, setApplications] = useState<Application[] | null>(() => {
        const storedApps = localStorage.getItem(applicationsStorageKey);
        return storedApps ? JSON.parse(storedApps) : null;
    });*/
    const [applications, setApplications] = useState<Application[] | null>(null);

    const [selectedApplication, setSelectedApplication] = useState<Application | null>(() => {
        const storedSelectedApp = localStorage.getItem(selectedApplicationStorageKey);
        return storedSelectedApp ? JSON.parse(storedSelectedApp) : null;
    });

    const [storedOverviewData, setStoredOverviewData] = useState<{ [service_key: string]: OverviewData } | null>(() => {
        const storedData = localStorage.getItem(storedOverviewDataKey);
        return storedData ? JSON.parse(storedData) : null;
    });

    const loadAllApplications = useCallback(
        async (createdApplication: string | null = null) => {
            try {
                const response = await axios.get(`${baseApiUrl}/dashboard/applications`, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                const fetchedApps: Application[] = response.data || [];
                setApplications(fetchedApps);
                localStorage.setItem(applicationsStorageKey, JSON.stringify(fetchedApps));

                if (
                    (!selectedApplication ||
                        !fetchedApps.find(app => app.service_key === selectedApplication.service_key)) &&
                    fetchedApps.length > 0
                ) {
                    setSelectedApplication(fetchedApps[0]);
                    localStorage.setItem(selectedApplicationStorageKey, JSON.stringify(fetchedApps[0]));
                }

                if (createdApplication) {
                    const newlyCreatedApp = fetchedApps.find(app => app.service_key === createdApplication) || null;
                    setSelectedApplication(newlyCreatedApp);
                    localStorage.setItem(selectedApplicationStorageKey, JSON.stringify(newlyCreatedApp));
                }
            } catch (error) {
                console.error("Failed to load applications:", error);
                throw error;
            }
        },
        [selectedApplication]
    );

    const createApplication = useCallback(
        async (name: string, platform: string, steam_url?: string) => {
            try {
                const response = await axios.post(
                    `${baseApiUrl}/dashboard/applications`,
                    { name, platform, steam_url },
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );

                const newApp: Application = response.data;
                await loadAllApplications(newApp.service_key);
            } catch (error) {
                console.error("Failed to create application:", error);
                throw error;
            }
        },
        [loadAllApplications]
    );

    const deleteApplication = useCallback(
        async (service_key: string) => {
            try {
                await axios.delete(`${baseApiUrl}/dashboard/applications`, {
                    data: { service_key },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                await loadAllApplications();
            } catch (error) {
                console.error("Failed to delete application:", error);
                throw error;
            }
        },
        [loadAllApplications]
    );

    const addAccountToApplication = useCallback(async (service_key: string, account_id: string) => {
        try {
            await axios.post(
                `${baseApiUrl}/dashboard/applications/add-account`,
                { service_key, account_id },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            // Optionally, refresh or handle state updates
        } catch (error) {
            console.error("Failed to add account:", error);
            throw error;
        }
    }, []);

    const getOverviewData = useCallback(
        async (service_key: string, start_date: string, end_date: string, filters?: Record<string, (string | number)[]>): Promise<OverviewData> => {
            try {
                const response = await axios.post(
                    `${baseApiUrl}/dashboard/overview`,
                    {
                        service_key,
                        start_date,
                        end_date,
                        filters
                    },
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );

                const data: OverviewData = response.data;
                setStoredOverviewData(prev => {
                    const updated = { ...prev, [service_key]: data };
                    localStorage.setItem(storedOverviewDataKey, JSON.stringify(updated));
                    return updated;
                });
                return data;
            } catch (error) {
                console.error("Failed to fetch overview data:", error);
                throw error;
            }
        },
        []
    );

    const getLiveFeedData = useCallback(async (service_key: string): Promise<AnalyticsLog[]> => {
        try {
            const response = await axios.post(
                `${baseApiUrl}/dashboard/live`,
                { service_key },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            return response.data.results;
        } catch (error) {
            console.error("Failed to fetch live feed data:", error);
            throw error;
        }
    }, []);

    const getAllAddedAccounts = useCallback(async (service_key: string): Promise<string[]> => {
        try {
            const response = await axios.post(
                `${baseApiUrl}/dashboard/applications/get-accounts`,
                { service_key },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.error("Failed to fetch added accounts:", error);
            throw error;
        }
    }, []);

    const removeAccountFromApplication = useCallback(async (service_key: string, account_id: string) => {
        try {
            await axios.post(
                `${baseApiUrl}/dashboard/applications/remove-account`,
                { service_key, account_id },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            // Optionally, refresh or handle state updates
        } catch (error) {
            console.error("Failed to remove account:", error);
            throw error;
        }
    }, []);

    // NEW: getMetadata method
    const getMetadata = useCallback(
        async (service_key: string, start_date: string, end_date: string, get_full_event_data?: boolean): Promise<MetadataResponse> => {
            try {
                const fullData = get_full_event_data || false;
                const response = await axios.post(
                    `${baseApiUrl}/dashboard/metadata`,
                    {
                        service_key,
                        start_date,
                        end_date,
                        get_full_event_data: fullData
                    },
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );
                return response.data as MetadataResponse;
            } catch (error) {
                console.error("Failed to fetch metadata:", error);
                throw error;
            }
        },
        []
    );

    // useEffect to load applications when user is signed in
    useEffect(() => {
        if (authStatus === AuthStatus.SignedIn) {
            if (!applications) {
                loadAllApplications();
            }
        } else if (authStatus === AuthStatus.SignedOut) {
            // User is logged out, clear all state and localStorage
            setApplications(null);
            setSelectedApplication(null);
            setStoredOverviewData(null);
            localStorage.removeItem(applicationsStorageKey);
            localStorage.removeItem(selectedApplicationStorageKey);
            localStorage.removeItem(storedOverviewDataKey);
        }
    }, [authStatus, loadAllApplications, applications]);

    // Ensure that selectedApplication is valid (exists in applications)
    useEffect(() => {
        if (selectedApplication && applications) {
            const exists = applications.find(app => app.service_key === selectedApplication.service_key);
            if (!exists) {
                setSelectedApplication(null);
                localStorage.removeItem(selectedApplicationStorageKey);
            }
        }
    }, [applications, selectedApplication]);

    // Persist selected application whenever it changes
    useEffect(() => {
        if (selectedApplication) {
            localStorage.setItem(selectedApplicationStorageKey, JSON.stringify(selectedApplication));
        } else {
            localStorage.removeItem(selectedApplicationStorageKey);
        }
    }, [selectedApplication]);

    // Persist storedOverviewData whenever it changes
    useEffect(() => {
        if (storedOverviewData) {
            localStorage.setItem(storedOverviewDataKey, JSON.stringify(storedOverviewData));
        } else {
            localStorage.removeItem(storedOverviewDataKey);
        }
    }, [storedOverviewData]);

    const state: IAppState = {
        applications,
        selectedApplication,
        storedOverviewData,
        loadAllApplications,
        createApplication,
        deleteApplication,
        selectApplication: setSelectedApplication,
        addAccountToApplication,
        getOverviewData,
        getLiveFeedData,
        getAllAddedAccounts,
        removeAccountFromApplication,
        getMetadata,
    };

    return (
        <AppStateContext.Provider value={state}>
            {children}
        </AppStateContext.Provider>
    );
};

export default AppStateProvider;
