// ReportBuilder.tsx
import React, { useState, useContext, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    HStack,
    Text,
    Select,
    Divider,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    Center,
    useDisclosure,
} from '@chakra-ui/react';
import { RangeDatepicker } from '../Components/chakra-dayzed-datepicker/src/index';
import AnalyticsFilters from './AnalyticsFilters';
import EventBuilder, { EventSelection } from './EventBuilder';
import { AppStateContext, MetadataResponse } from '../Contexts/AppStateContext';
import axios from 'axios';
import { baseApiUrl } from '../Utilities/constants';

interface ReportData {
    daily: { [date: string]: { [event: string]: { [valueKey: string]: number } } };
    dailyUsers: { [date: string]: { [event: string]: number } };
    total: { [event: string]: { [valueKey: string]: number } };
    users: { [event: string]: number };
}

interface DailyRow {
    date: string;
    event: string;
    valueKey: string;
    daily: number;
    users: number;
}

interface TotalRow {
    event: string;
    valueKey: string;
    total: number;
    users: number;
}

const ReportBuilder: React.FC = () => {
    const appState = useContext(AppStateContext);
    const containerHeight = 'calc(100vh - 100px)';

    const [dateRange, setDateRange] = useState<Date[]>(() => {
        const today = new Date();
        const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3);
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        const end = new Date(tomorrow.getTime() - 1);
        return [start, end];
    });

    const [metadataResponse, setMetadataResponse] = useState<MetadataResponse | null>(null);
    const [filterState, setFilterState] = useState<Record<string, (string | number)[]>>({});
    const filtersDisclosure = useDisclosure();
    const eventBuilderDisclosure = useDisclosure();
    const [eventSelections, setEventSelections] = useState<EventSelection[]>([]);
    const [selectedAggregation, setSelectedAggregation] = useState<string>('Count');
    const [reportData, setReportData] = useState<ReportData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Sorting state for daily rows.
    const [sortColumn, setSortColumn] = useState<keyof DailyRow>('date');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    // Sorting state for overall totals (footer).
    const [footerSortColumn, setFooterSortColumn] = useState<keyof TotalRow>('event');
    const [footerSortDirection, setFooterSortDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        if (appState.selectedApplication?.service_key && dateRange.length === 2) {
            const serviceKey = appState.selectedApplication.service_key;
            const startISO = dateRange[0].toISOString();
            const endISO = dateRange[1].toISOString();
            // Note: we pass true to get full event data.
            appState
                .getMetadata(serviceKey, startISO, endISO, true)
                .then((res) => {
                    setMetadataResponse(res);
                    if (Object.keys(filterState).length === 0 && res.metadata) {
                        const initialFilters: Record<string, (string | number)[]> = {};
                        Object.keys(res.metadata).forEach((key) => {
                            initialFilters[key] = [];
                        });
                        setFilterState(initialFilters);
                    }
                })
                .catch((err) => {
                    console.error('Error fetching metadata:', err);
                });
        }
    }, [dateRange, appState.selectedApplication]);

    // Compute event options based on metadataResponse.
    // If eventData is a mapping (full event data), we convert it to a Record<string, string[]>.
    // Otherwise (if it's an array), we convert each event name into a record with an empty array.
    const eventOptions: Record<string, string[]> = useMemo(() => {
        if (metadataResponse && metadataResponse.eventData) {
            if (Array.isArray(metadataResponse.eventData)) {
                // When only an array of event names is returned.
                return metadataResponse.eventData.reduce((acc, ev) => {
                    acc[ev] = [];
                    return acc;
                }, {} as Record<string, string[]>);
            } else {
                // When full event data is returned.
                const options: Record<string, string[]> = {};
                for (const [eventName, valueKeys] of Object.entries(metadataResponse.eventData)) {
                    options[eventName] = (valueKeys as any[]).map((v) => String(v));
                }
                return options;
            }
        }
        // Fallback (could be empty if no metadata loaded yet)
        return {};
    }, [metadataResponse]);

    const handleRunReport = async () => {
        if (!appState.selectedApplication?.service_key) return;
        // Clear previous reportData to clear footers while loading.
        setReportData(null);
        setIsLoading(true);
        try {
            const payload = {
                service_key: appState.selectedApplication.service_key,
                start_date: dateRange[0].toISOString(),
                end_date: dateRange[1].toISOString(),
                filters: filterState,
                events: eventSelections.map(({ eventName, valueKeys }) => ({ eventName, valueKeys })),
                aggregation: selectedAggregation,
            };
            const response = await axios.post(`${baseApiUrl}/dashboard/report`, payload, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            });
            setReportData(response.data);
        } catch (error) {
            console.error('Failed to run report:', error);
        }
        setIsLoading(false);
    };

    // Flatten daily data into an array including all dates in the range.
    const dailyRows: DailyRow[] = useMemo(() => {
        const rows: DailyRow[] = [];
        // Build an array of date strings (YYYY-MM-DD) for the entire range.
        const dateArray: string[] = [];
        const start = new Date(dateRange[0]);
        const end = new Date(dateRange[1]);
        const current = new Date(start);
        while (current <= end) {
            const formattedDate = current.toISOString().split('T')[0];
            dateArray.push(formattedDate);
            current.setDate(current.getDate() + 1);
        }

        // For each date in the range, add rows for each event in eventSelections.
        dateArray.forEach((dateStr) => {
            eventSelections.forEach((selection) => {
                const event = selection.eventName;
                const valueKeys = selection.valueKeys;
                if (valueKeys.length === 0) {
                    // No value keys means a single aggregated number.
                    const daily = reportData?.daily?.[dateStr]?.[event]?.[''] || 0;
                    const users = reportData?.dailyUsers?.[dateStr]?.[event] || 0;
                    rows.push({ date: dateStr, event, valueKey: '', daily, users });
                } else {
                    valueKeys.forEach((valueKey) => {
                        const daily = reportData?.daily?.[dateStr]?.[event]?.[valueKey] || 0;
                        const users = reportData?.dailyUsers?.[dateStr]?.[event] || 0;
                        rows.push({ date: dateStr, event, valueKey, daily, users });
                    });
                }
            });
        });
        return rows;
    }, [reportData]);

    // Sorting for daily rows.
    const sortedRows = useMemo(() => {
        const sorted = [...dailyRows];
        sorted.sort((a, b) => {
            let cmp = 0;
            if (a[sortColumn] < b[sortColumn]) cmp = -1;
            else if (a[sortColumn] > b[sortColumn]) cmp = 1;
            return sortDirection === 'asc' ? cmp : -cmp;
        });
        return sorted;
    }, [dailyRows, sortColumn, sortDirection]);

    const handleSort = (col: keyof DailyRow) => {
        if (sortColumn === col) {
            setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            setSortColumn(col);
            // First click sorts descending.
            setSortDirection('desc');
        }
    };

    // Build overall totals for the footer.
    const overallTotalsRows: TotalRow[] = useMemo(() => {
        const rows: TotalRow[] = [];
        if (reportData?.total && reportData?.users) {
            for (const [event, values] of Object.entries(reportData.total)) {
                const users = reportData.users[event] || 0;
                for (const [valueKey, total] of Object.entries(values)) {
                    rows.push({ event, valueKey, total, users });
                }
            }
        }
        return rows;
    }, [reportData]);

    // Sorting for footer totals.
    const sortedTotalsRows = useMemo(() => {
        const sorted = [...overallTotalsRows];
        sorted.sort((a, b) => {
            let cmp = 0;
            if (a[footerSortColumn] < b[footerSortColumn]) cmp = -1;
            else if (a[footerSortColumn] > b[footerSortColumn]) cmp = 1;
            return footerSortDirection === 'asc' ? cmp : -cmp;
        });
        return sorted;
    }, [overallTotalsRows, footerSortColumn, footerSortDirection]);

    const handleFooterSort = (col: keyof TotalRow) => {
        if (footerSortColumn === col) {
            setFooterSortDirection(footerSortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            setFooterSortColumn(col);
            setFooterSortDirection('desc');
        }
    };

    const headerBg = useColorModeValue('gray.200', 'gray.700');

    return (
        <Flex direction="column" w="100%" h={containerHeight} p={3} boxSizing="border-box">
            {/* Header */}
            <Flex direction="row" justifyContent="space-between" alignItems="center" pl={5} pr={2} pb={3} mt={1}>
                <Center>
                    <Text fontWeight={500} w="100%" textAlign="left">
                        Report Builder
                    </Text>
                </Center>
                <Center>
                    <Button
                        variant="outline"
                        onClick={handleRunReport}
                        isLoading={isLoading}
                        loadingText="Run Report"
                        disabled={!appState.selectedApplication?.service_key || isLoading}
                    >
                        Run Report
                    </Button>
                </Center>
            </Flex>
            <Divider mb={3} />

            {/* Top Controls */}
            <Flex alignItems="center" justifyContent="flex-start" mb={3} pl={2} pr={2} gap={4}>
                <RangeDatepicker
                    selectedDates={dateRange}
                    onDateChange={(dates) => {
                        if (dates.length === 1) {
                            setDateRange(dates);
                        } else if (dates.length === 2) {
                            const [start, end] = dates;
                            const newStart = new Date(start.getFullYear(), start.getMonth(), start.getDate());
                            const endNextMidnight = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
                            const newEnd = new Date(endNextMidnight.getTime() - 1);
                            setDateRange([newStart, newEnd]);
                        }
                    }}
                    maxDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                />
                <AnalyticsFilters
                    isOpen={filtersDisclosure.isOpen}
                    onOpen={filtersDisclosure.onOpen}
                    onClose={filtersDisclosure.onClose}
                    metadataResponse={metadataResponse}
                    filterState={filterState}
                    setFilterState={setFilterState}
                    metadataLoading={false}
                />
                <EventBuilder
                    isOpen={eventBuilderDisclosure.isOpen}
                    onOpen={eventBuilderDisclosure.onOpen}
                    onClose={eventBuilderDisclosure.onClose}
                    eventSelections={eventSelections}
                    setEventSelections={setEventSelections}
                    defaultEventOptions={eventOptions} // Pass in the dynamically computed options!
                />
                <HStack spacing={2}>
                    <Text>Aggregate:</Text>
                    <Select
                        value={selectedAggregation}
                        onChange={(e) => setSelectedAggregation(e.target.value)}
                        width="180px"
                        fontSize="md"
                    >
                        <option value="Count">Count</option>
                        <option value="Unique">Unique</option>
                        <option value="Min">Min</option>
                        <option value="Max">Max</option>
                        <option value="Median">Median</option>
                        <option value="Average">Average</option>
                        <option value="Percent">Percent</option>
                        <option value="Sum">Sum</option>
                    </Select>
                </HStack>
            </Flex>
            <Divider mb={2} />

            {/* Report Table with Sticky Header */}
            <Box flex="1" overflowY="auto" borderWidth="1px" borderRadius="md" p={0} mt={1}>
                {isLoading ? (
                    <Flex w="100%" h="100%" direction="column" justifyContent="center" alignItems="center">
                        <Spinner size="xl" />
                    </Flex>
                ) : dailyRows.length > 0 ? (
                    <Table variant="simple" layout="fixed">
                        <colgroup>
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <Thead position="sticky" top={0} bg={headerBg} zIndex={1}>
                            <Tr>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleSort('date')}>
                                    Date {sortColumn === 'date' ? (sortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleSort('event')}>
                                    Event {sortColumn === 'event' ? (sortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleSort('valueKey')}>
                                    Value Key {sortColumn === 'valueKey' ? (sortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleSort('users')}>
                                    Daily Users {sortColumn === 'users' ? (sortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleSort('daily')}>
                                    Daily Value {sortColumn === 'daily' ? (sortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedRows.map((row, idx) => (
                                <Tr key={idx}>
                                    <Td>{row.date}</Td>
                                    <Td>{row.event}</Td>
                                    <Td>{row.valueKey || 'N/A'}</Td>
                                    <Td>{row.users}</Td>
                                    <Td>{row.daily}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Flex w="100%" h="100%" direction="column" justifyContent="center" alignItems="center">
                        <Text fontSize="lg" color="gray.500">
                            No report data to display.
                        </Text>
                    </Flex>
                )}
            </Box>

            {/* Footer: Overall Totals */}
            {!isLoading && reportData?.total && reportData?.users && (
                <Box borderWidth="1px" borderRadius="md" p={0} mt={4}>
                    <Table variant="simple" layout="fixed">
                        <colgroup>
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <Thead bg={headerBg}>
                            <Tr>
                                <Th userSelect="none"></Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleFooterSort('event')}>
                                    Event {footerSortColumn === 'event' ? (footerSortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleFooterSort('valueKey')}>
                                    Value Key {footerSortColumn === 'valueKey' ? (footerSortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleFooterSort('users')}>
                                    Total Users {footerSortColumn === 'users' ? (footerSortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                                <Th userSelect="none" cursor="pointer" onClick={() => handleFooterSort('total')}>
                                    Aggregate Value {footerSortColumn === 'total' ? (footerSortDirection === 'desc' ? '▼' : '▲') : ''}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedTotalsRows.map((row, idx) => (
                                <Tr key={idx}>
                                    <Td></Td>
                                    <Td>{row.event}</Td>
                                    <Td>{row.valueKey || 'N/A'}</Td>
                                    <Td>{row.users}</Td>
                                    <Td>{row.total}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )}
        </Flex>
    );
};

export default ReportBuilder;
