import * as React from "react"
import {
  ChakraProvider,
  theme,
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react"

import {
  Route,
  BrowserRouter,
  Routes,
  Navigate
} from "react-router-dom";

import TopBar from "./Components/TopBar"
import HomePage from "./Components/HomePage"
import AuthPage from "./Components/AuthPage"
import DashboardLanding from "./Components/DashboardLanding"
import ApplicationSettings from "./Components/ApplicationSettings";
import AnalyticsOverview from "./Components/AnalyticsOverview";
import LiveActivityFeed from "./Components/LiveActivityFeed";
import ReportBuilder from "./Components/ReportBuilder";
import AccountSettings from "./Components/AccountSettings";

import AuthProvider, { AuthIsNotSignedIn, AuthIsSignedIn } from "./Contexts/AuthContext";
import AppStateProvider from "./Contexts/AppStateContext";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <AppStateProvider>
          <Stack dir="column" overflowY='hidden'>
            <Flex direction="column" justifyContent="space-between" pt={2}>
              <BrowserRouter>
                <TopBar />
                <Routes>
                  <Route path="/" element={
                    <div>
                      <AuthIsSignedIn><Navigate to="/dashboard" /></AuthIsSignedIn>
                      <AuthIsNotSignedIn><HomePage /></AuthIsNotSignedIn>
                    </div>
                  } />
                  <Route path="/login" element={
                    <div>
                      <AuthIsSignedIn><Navigate to="/" /></AuthIsSignedIn>
                      <AuthIsNotSignedIn><AuthPage /></AuthIsNotSignedIn>
                    </div>
                  } />
                  <Route path="/dashboard">
                    <Route path="/dashboard" element={
                      <div>
                        <AuthIsSignedIn><DashboardLanding mainBodyComponent={<AnalyticsOverview />} /></AuthIsSignedIn>
                        <AuthIsNotSignedIn><Navigate to="/" /></AuthIsNotSignedIn>
                      </div>
                    } />
                    <Route path="/dashboard/settings" element={
                      <div>
                        <AuthIsSignedIn><DashboardLanding mainBodyComponent={<ApplicationSettings />} /></AuthIsSignedIn>
                        <AuthIsNotSignedIn><Navigate to="/" /></AuthIsNotSignedIn>
                      </div>
                    } />
                    <Route path="/dashboard/live-activity-feed" element={
                      <div>
                        <AuthIsSignedIn><DashboardLanding mainBodyComponent={<LiveActivityFeed />} /></AuthIsSignedIn>
                        <AuthIsNotSignedIn><Navigate to="/" /></AuthIsNotSignedIn>
                      </div>
                    } />
                    <Route path="/dashboard/report-builder" element={
                      <div>
                        <AuthIsSignedIn><DashboardLanding mainBodyComponent={<ReportBuilder />} /></AuthIsSignedIn>
                        <AuthIsNotSignedIn><Navigate to="/" /></AuthIsNotSignedIn>
                      </div>
                    } />
                    <Route path="/dashboard/account-settings" element={
                      <div>
                        <AuthIsSignedIn><DashboardLanding mainBodyComponent={<AccountSettings />} /></AuthIsSignedIn>
                        <AuthIsNotSignedIn><Navigate to="/" /></AuthIsNotSignedIn>
                      </div>
                    } />
                  </Route>
                </Routes>
              </BrowserRouter>
            </Flex>
          </Stack>
        </AppStateProvider>
      </AuthProvider>
    </ChakraProvider>
  )
}
