import {
    Button,
    ButtonGroup,
    Card,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    VStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Box,
    useToast,
    IconButton,
    FormHelperText,
    Flex,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import React, { useContext, useState } from 'react';

import { AuthContext } from '../Contexts/AuthContext';

const AccountSettings = () => {
    const authContext = useContext(AuthContext);

    // State variables for password fields
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    // State variables for error and success messages
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    // Loading state for the change password action
    const [isLoading, setIsLoading] = useState(false);

    // Password visibility toggles
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Toast for success notifications
    const toast = useToast();

    // Handler for changing the password
    const handleChangePassword = async () => {
        // Reset previous messages
        setError(null);
        setSuccess(null);

        // Frontend Validation
        if (!oldPassword || !newPassword || !confirmNewPassword) {
            setError('All fields are required.');
            return;
        }

        if (newPassword.length < 8 || newPassword.length > 128) {
            setError('New password must be between 8 and 128 characters.');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setError('New password and confirmation do not match.');
            return;
        }

        // Enhanced validation for password complexity
        const validatePassword = (password: string): string | null => {
            const requirements = [
                /[A-Z]/.test(password), // Uppercase
                /[a-z]/.test(password), // Lowercase
                /[0-9]/.test(password), // Number
                /[^A-Za-z0-9]/.test(password), // Special character
            ];
            const fulfilled = requirements.filter(Boolean).length;

            if (fulfilled < 3) {
                return 'Password must include at least 3 of the following: uppercase, lowercase, number, special character.';
            }

            return null;
        };

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        try {
            setIsLoading(true);
            await authContext.changePassword(oldPassword, newPassword);
            setSuccess('Password changed successfully.');

            // Reset form fields
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');

            // Show success toast
            toast({
                title: 'Success',
                description: 'Your password has been updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (e: any) {
            // Extract error message from response
            if (e.response && e.response.data && e.response.data.error) {
                setError(e.response.data.error);
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <HStack w="100%" justifyContent="flex-start">
            <VStack p={3} w="100%" maxW="600px" align="stretch">
                <Text fontWeight={500} w='100%' textAlign='left'>Account Settings</Text>
                <Divider />
                <VStack align="flex-start" mt={2} spacing={4} w="100%">
                    {/* User Email */}
                    <Card w="100%" p={3}>
                        <Text fontWeight={500} textAlign="left">
                            {authContext.user?.email || 'No Email Provided'}
                        </Text>
                    </Card>

                    {/* User ID */}
                    <Card w="100%" p={2}>
                        <Flex justify="space-between" align="center" w="100%">
                            <Text fontWeight={500} textAlign="left">
                                {authContext.user?.id || 'No ID Provided'}
                            </Text>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                    navigator.clipboard.writeText(authContext.user?.id || '')
                                }
                            >
                                Copy
                            </Button>
                        </Flex>
                    </Card>


                    <Divider />

                    {/* Change Password Form */}
                    <Card w="100%" p={4} variant="outline">
                        <Text fontWeight={500} textAlign="left" mb={2}>
                            Change Password
                        </Text>
                        <Divider mb={2} />
                        <FormControl isRequired>
                            {/* Current Password */}
                            <FormLabel>Current Password</FormLabel>
                            <InputGroup>
                                <Input
                                    value={oldPassword}
                                    type={showOldPassword ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    placeholder="Enter your current password"
                                />
                                <InputRightElement>
                                    <IconButton
                                        variant="ghost"
                                        aria-label={
                                            showOldPassword ? 'Hide password' : 'Show password'
                                        }
                                        icon={showOldPassword ? <ViewOffIcon /> : <ViewIcon />}
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                    />
                                </InputRightElement>
                            </InputGroup>

                            {/* New Password */}
                            <FormLabel mt={4}>New Password</FormLabel>
                            <InputGroup>
                                <Input
                                    value={newPassword}
                                    type={showNewPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter your new password"
                                />
                                <InputRightElement>
                                    <IconButton
                                        variant="ghost"
                                        aria-label={
                                            showNewPassword ? 'Hide password' : 'Show password'
                                        }
                                        icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormHelperText>
                                Must be between 8 and 128 characters long.
                            </FormHelperText>

                            {/* Confirm New Password */}
                            <FormLabel mt={4}>Confirm New Password</FormLabel>
                            <InputGroup>
                                <Input
                                    value={confirmNewPassword}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    placeholder="Confirm your new password"
                                />
                                <InputRightElement>
                                    <IconButton
                                        variant="ghost"
                                        aria-label={
                                            showConfirmPassword
                                                ? 'Hide password'
                                                : 'Show password'
                                        }
                                        icon={
                                            showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />
                                        }
                                        onClick={() =>
                                            setShowConfirmPassword(!showConfirmPassword)
                                        }
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormHelperText>Must match new password.</FormHelperText>

                            {/* Display Error Alert */}
                            {error && (
                                <Alert
                                    status="error"
                                    borderRadius="md"
                                    width="100%"
                                    mt={4}
                                    position="relative"
                                >
                                    <AlertIcon />
                                    <Box flex="1" minWidth="0">
                                        <AlertTitle>Error!</AlertTitle>
                                        <AlertDescription whiteSpace="pre-wrap" wordBreak="break-word">
                                            {error}
                                        </AlertDescription>
                                    </Box>
                                    <CloseButton
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                        onClick={() => setError(null)}
                                    />
                                </Alert>
                            )}

                            {/* Display Success Alert */}
                            {success && (
                                <Alert
                                    status="success"
                                    borderRadius="md"
                                    width="100%"
                                    mt={4}
                                    position="relative"
                                >
                                    <AlertIcon />
                                    <Box flex="1" minWidth="0">
                                        <AlertTitle>Success!</AlertTitle>
                                        <AlertDescription whiteSpace="pre-wrap" wordBreak="break-word">
                                            {success}
                                        </AlertDescription>
                                    </Box>
                                    <CloseButton
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                        onClick={() => setSuccess(null)}
                                    />
                                </Alert>
                            )}

                            <Divider mt={5} mb={3} />

                            {/* Submit Button */}
                            <Box textAlign="center">
                                <Button
                                    mt={2}
                                    variant="solid"
                                    type="button"
                                    minWidth="200px"
                                    onClick={handleChangePassword}
                                    isLoading={isLoading}
                                    loadingText="Changing..."
                                >
                                    Change Password
                                </Button>
                            </Box>
                        </FormControl>
                    </Card>
                </VStack>
            </VStack>
        </HStack>
    );
};

export default AccountSettings;
