import {
    Button,
    Center,
    Divider,
    Flex,
    HStack,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useTheme,
    VStack,
    Box,
    BoxProps,
    Tooltip,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AppStateContext } from '../Contexts/AppStateContext';
import Moment from 'react-moment';

const LiveActivityFeed = () => {
    const appState = useContext(AppStateContext);
    const theme = useTheme();

    const [liveActivityData, setLiveActivityData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Fetch live activity data
    const fetchData = useCallback(async () => {
        if (!appState.selectedApplication?.service_key) return;

        setIsLoading(true);
        try {
            const result = await appState.getLiveFeedData?.(
                appState.selectedApplication.service_key
            );
            setLiveActivityData(result || []);
        } catch (error) {
            console.error('Error fetching live activity data:', error);
            setLiveActivityData([]);
        } finally {
            setIsLoading(false);
        }
    }, [appState]);

    // Fetch data when the selected application changes
    useEffect(() => {
        if (appState.selectedApplication?.service_key) {
            fetchData();
        } else {
            setLiveActivityData([]);
            setIsLoading(false);
        }
    }, [appState.selectedApplication?.service_key, fetchData]);

    // Outer container properties (fixed overall height)
    const outerContainerProps: BoxProps = {
        w: '100%',
        h: 'calc(100vh - 100px)',
        p: 3,
        boxSizing: 'border-box',
    };

    return (
        <Flex direction="column" {...outerContainerProps}>
            {/* Header */}
            <Flex
                w="100%"
                direction="row"
                justifyContent="space-between"
                pl={5}
                pr={2}
                pb={3}
                mt={0}
            >
                <Center pt={1}>
                    <Text fontWeight={500} w="100%" textAlign="left">
                        Live Activity Feed
                    </Text>
                </Center>
                <Center pt={1}>
                    <Flex direction="row">
                        <Button
                            variant="outline"
                            onClick={fetchData}
                            isLoading={isLoading}
                            loadingText="Refreshing"
                            disabled={!appState.selectedApplication?.service_key || isLoading}
                        >
                            Refresh
                        </Button>
                    </Flex>
                </Center>
            </Flex>
            <Divider />

            {/* Scrollable Content Container */}
            <Box flex="1" minH="0" overflowY="auto">
                {!appState.selectedApplication?.service_key ? (
                    // No Application Selected
                    <Flex
                        w="100%"
                        h="100%"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        pl={5}
                    >
                        <Text fontSize="lg" color="gray.500">
                            No application selected!
                        </Text>
                    </Flex>
                ) : isLoading ? (
                    // Loading Spinner
                    <Flex
                        w="100%"
                        h="100%"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        pl={5}
                    >
                        <Spinner size="xl" />
                    </Flex>
                ) : liveActivityData && liveActivityData.length ? (
                    // Live Activity Data Table
                    <TableContainer overflowX="auto">
                        <Table variant="simple">
                            <Thead
                                position="sticky"
                                top={0}
                                zIndex={100}
                                bg={theme.colors['chakra-body-bg']}
                                boxShadow="sm"
                            >
                                <Tr>
                                    <Th>Timestamp</Th>
                                    <Th>User</Th>
                                    <Th>Action</Th>
                                    <Th>Payload</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {liveActivityData.map((activity: any, index: number) => (
                                    <Tr key={`${activity.timestamp}-${index}`}>
                                        <Td>
                                            <Text fontWeight={400}>
                                                <Moment local format="YYYY/MM/DD HH:mm:ss">
                                                    {activity.timestamp}
                                                </Moment>
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text fontWeight={400}>{activity.user_id}</Text>
                                        </Td>
                                        <Td>
                                            <Text fontWeight={400}>{activity.action}</Text>
                                        </Td>
                                        <Td>
                                            <Tooltip
                                                placement="top"
                                                hasArrow
                                                offset={[0, 10]}
                                                maxW="800px" // Set max width for the tooltip popover
                                                label={
                                                    <Box>
                                                        <pre
                                                            style={{
                                                                whiteSpace: 'pre-wrap',
                                                                margin: 0,
                                                            }}
                                                        >
                                                            {JSON.stringify(activity.payload, null, 2)}
                                                        </pre>
                                                    </Box>
                                                }
                                                aria-label="Full payload"
                                            >
                                                <Box
                                                    borderWidth="1px"
                                                    borderRadius="md"
                                                    p={2}
                                                    w="100%"
                                                    minW="200px"
                                                    maxW="450px"
                                                    overflow="hidden"
                                                    whiteSpace="nowrap"
                                                    textOverflow="ellipsis"
                                                    cursor="default"
                                                >
                                                    {JSON.stringify(activity.payload)}
                                                </Box>
                                            </Tooltip>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : (
                    // No Live Activities
                    <Flex
                        w="100%"
                        h="100%"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        pl={5}
                    >
                        <Text fontSize="lg" color="gray.500">
                            No live activities.
                        </Text>
                    </Flex>
                )}
            </Box>
        </Flex>
    );
};

export default LiveActivityFeed;
