import {
    Button,
    ButtonGroup,
    Card,
    Divider,
    HStack,
    Input,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
    VStack,
    FormControl,
    FormLabel,
    FormHelperText,
    IconButton,
    Box,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Flex,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState, useCallback } from 'react';

import { AppStateContext } from '../Contexts/AppStateContext';

const ApplicationSettings = () => {
    const appState = useContext(AppStateContext);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [accountID, setAccountID] = useState('');
    const [addedAccounts, setAddedAccounts] = useState<string[]>([]);
    // Lower UI flags now default to false.
    const [showAddedAccounts, setShowAddedAccounts] = useState(false);
    const [showAddAccountUI, setShowAddAccountUI] = useState(false);
    const [showDeleteApplicationUI, setShowDeleteApplicationUI] = useState(false);
    const [isAddAccountPopoverOpen, setIsAddAccountPopoverOpen] = useState(false);
    const [addAccountError, setAddAccountError] = useState<string | null>(null);
    const [addAccountSuccess, setAddAccountSuccess] = useState<string | null>(null);
    const [removeAccountError, setRemoveAccountError] = useState<string | null>(null);
    const [removeAccountSuccess, setRemoveAccountSuccess] = useState<string | null>(null);
    const [loadingAccounts, setLoadingAccounts] = useState<boolean>(false);
    const toast = useToast();

    const serviceKey = appState.selectedApplication?.service_key || "";

    // Function to fetch added accounts and determine lower UI visibility.
    const fetchAddedAccounts = useCallback(async () => {
        if (!serviceKey || !appState.selectedApplication || !appState.selectedApplication.service_key) return;
        setLoadingAccounts(true);
        try {
            const accounts = await appState.getAllAddedAccounts(serviceKey);
            setAddedAccounts(accounts);
            // On success, enable the lower UI sections.
            setShowAddedAccounts(true);
            setShowAddAccountUI(true);
            setShowDeleteApplicationUI(true);
        } catch (err: any) {
            console.error("Error fetching added accounts:", err);
            // If the error is 403, do not show these lower UI sections.
            if (err.response && err.response.status === 403) {
                setShowAddedAccounts(false);
                setShowAddAccountUI(false);
                setShowDeleteApplicationUI(false);
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to fetch added accounts.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setLoadingAccounts(false);
        }
    }, [appState, serviceKey, toast]);

    useEffect(() => {
        fetchAddedAccounts();
    }, [fetchAddedAccounts]);

    const handleAddAccount = async () => {
        // Reset previous messages.
        setAddAccountError(null);
        setAddAccountSuccess(null);

        if (!accountID.trim()) {
            setAddAccountError('Account ID is required.');
            return;
        }

        try {
            await appState.addAccountToApplication(serviceKey, accountID.trim());
            setAddAccountSuccess(`Account [${accountID.trim()}] added successfully.`);
            setAccountID('');
            toast({
                title: 'Success',
                description: `Account [${accountID.trim()}] has been added.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            // Refresh the added accounts list.
            await fetchAddedAccounts();
        } catch (e: any) {
            console.error("Error adding account:", e);
            // If a 403 error is encountered, ensure the lower UI remains hidden.
            if (e.response && e.response.status === 403) {
                setShowAddAccountUI(false);
                setShowDeleteApplicationUI(false);
            } else {
                setAddAccountError('Failed to add account. Please try again.');
            }
        } finally {
            setIsAddAccountPopoverOpen(false);
        }
    };

    const handleDeleteApplication = async () => {
        // Reset previous messages.
        setRemoveAccountError(null);
        setRemoveAccountSuccess(null);

        try {
            await appState.deleteApplication(serviceKey);
            toast({
                title: 'Deleted',
                description: 'The application has been deleted.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            // Optionally reset or redirect the user after deletion.
        } catch (e: any) {
            console.error("Error deleting application:", e);
            toast({
                title: 'Error',
                description: 'Failed to delete application. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsPopoverOpen(false);
        }
    };

    const handleDeleteAccount = async (accountIDToDelete: string) => {
        // Reset previous messages.
        setRemoveAccountError(null);
        setRemoveAccountSuccess(null);

        try {
            await appState.removeAccountFromApplication(serviceKey, accountIDToDelete);
            setRemoveAccountSuccess(`Account [${accountIDToDelete}] removed successfully.`);
            toast({
                title: 'Deleted',
                description: `Account [${accountIDToDelete}] has been removed.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            // Refresh the added accounts list.
            await fetchAddedAccounts();
        } catch (e: any) {
            console.error("Error removing account:", e);
            setRemoveAccountError('Failed to remove account. Please try again.');
            toast({
                title: 'Error',
                description: 'Failed to remove account. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (!appState.selectedApplication) {
        return (
            <HStack w="100%" justifyContent="flex-start">
                <VStack p={3} w="100%" maxW="600px" align="stretch">
                    <Text fontWeight={500} w="100%" textAlign="left">
                        Application Settings
                    </Text>
                    <Divider />
                    <VStack w="100%" align="flex-start" mt={2} spacing={4}>
                        <Text
                            w="100%"
                            textAlign="center"
                            fontSize="lg"
                            color="gray.500"
                        >
                            No application selected!
                        </Text>
                    </VStack>
                </VStack>
            </HStack>
        );
    }

    // Compute whether any of the lower UI should be rendered.
    const showLowerUI = showAddAccountUI || showAddedAccounts || showDeleteApplicationUI;

    return (
        <HStack w="100%" justifyContent="flex-start">
            <VStack p={3} w="100%" maxW="600px" align="stretch">
                <Text fontWeight={500} w="100%" textAlign="left">
                    Application Settings
                </Text>
                <Divider />
                <VStack align="flex-start" mt={2} spacing={4} w="100%">
                    {/* Application Name */}
                    <Card w="100%" p={3}>
                        <Text fontWeight={500} textAlign="left">
                            {appState.selectedApplication?.name || 'No Name Provided'}
                        </Text>
                    </Card>

                    {/* Application Platform */}
                    <Card w="100%" p={3}>
                        <Text fontWeight={500} textAlign="left">
                            {appState.selectedApplication?.platform || 'No Platform Provided'}
                        </Text>
                    </Card>

                    {/* Service Key */}
                    <Card w="100%" p={2}>
                        <Flex justify="space-between" align="center" w="100%">
                            <Text fontWeight={500} textAlign="left">
                                {appState.selectedApplication?.service_key || 'No Service Key Provided'}
                            </Text>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        appState.selectedApplication?.service_key || ''
                                    )
                                }
                            >
                                Copy
                            </Button>
                        </Flex>
                    </Card>

                    {/* Conditionally render the divider only if at least one lower UI section is visible */}
                    {showLowerUI && <Divider />}

                    {/* Conditionally Render Add Account to Application UI */}
                    {showAddAccountUI && (
                        <Card w="100%" p={4} variant="outline">
                            <Text fontWeight={500} textAlign="left" mb={2}>
                                Add Account to Application
                            </Text>
                            <FormControl isRequired>
                                <FormLabel>Account ID</FormLabel>
                                <Input
                                    placeholder="Enter Account ID"
                                    value={accountID}
                                    onChange={(e) => setAccountID(e.target.value)}
                                />
                                <FormHelperText>
                                    Enter the Account ID you wish to add.
                                </FormHelperText>

                                {addAccountError && (
                                    <Alert
                                        status="error"
                                        borderRadius="md"
                                        width="100%"
                                        mt={4}
                                        position="relative"
                                    >
                                        <AlertIcon />
                                        <Box flex="1" minWidth="0">
                                            <AlertTitle>Error!</AlertTitle>
                                            <AlertDescription
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                            >
                                                {addAccountError}
                                            </AlertDescription>
                                        </Box>
                                        <CloseButton
                                            position="absolute"
                                            right="8px"
                                            top="8px"
                                            onClick={() => setAddAccountError(null)}
                                        />
                                    </Alert>
                                )}

                                {addAccountSuccess && (
                                    <Alert
                                        status="success"
                                        borderRadius="md"
                                        width="100%"
                                        mt={4}
                                        position="relative"
                                    >
                                        <AlertIcon />
                                        <Box flex="1" minWidth="0">
                                            <AlertTitle>Success!</AlertTitle>
                                            <AlertDescription
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                            >
                                                {addAccountSuccess}
                                            </AlertDescription>
                                        </Box>
                                        <CloseButton
                                            position="absolute"
                                            right="8px"
                                            top="8px"
                                            onClick={() => setAddAccountSuccess(null)}
                                        />
                                    </Alert>
                                )}

                                <Popover isOpen={isAddAccountPopoverOpen}>
                                    <PopoverTrigger>
                                        <Button
                                            mt={4}
                                            w="100%"
                                            onClick={() => {
                                                setIsAddAccountPopoverOpen(true);
                                                setIsPopoverOpen(false);
                                            }}
                                        >
                                            Add Account
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverHeader fontWeight="semibold">
                                            Confirmation
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <VStack p={3} align="stretch">
                                                <Text fontWeight={500} textAlign="left">
                                                    {`Are you sure you want to add account [${accountID.trim()}] to ${appState.selectedApplication?.name || 'this application'}?`}
                                                </Text>
                                            </VStack>
                                        </PopoverBody>
                                        <PopoverFooter display="flex" justifyContent="flex-end">
                                            <ButtonGroup size="sm">
                                                <Button variant="outline" onClick={() => setIsAddAccountPopoverOpen(false)}>
                                                    Cancel
                                                </Button>
                                                <Button colorScheme="green" onClick={handleAddAccount}>
                                                    Add
                                                </Button>
                                            </ButtonGroup>
                                        </PopoverFooter>
                                    </PopoverContent>
                                </Popover>
                            </FormControl>
                        </Card>
                    )}

                    {/* Conditionally Render Added Accounts Section */}
                    {showAddedAccounts && (
                        <Card w="100%" p={4} variant="outline">
                            <Text fontWeight={500} textAlign="left" mb={2}>
                                Added Accounts
                            </Text>

                            {removeAccountError && (
                                <Alert
                                    status="error"
                                    borderRadius="md"
                                    width="100%"
                                    mb={4}
                                    position="relative"
                                >
                                    <AlertIcon />
                                    <Box flex="1" minWidth="0">
                                        <AlertTitle>Error!</AlertTitle>
                                        <AlertDescription whiteSpace="pre-wrap" wordBreak="break-word">
                                            {removeAccountError}
                                        </AlertDescription>
                                    </Box>
                                    <CloseButton
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                        onClick={() => setRemoveAccountError(null)}
                                    />
                                </Alert>
                            )}

                            {removeAccountSuccess && (
                                <Alert
                                    status="success"
                                    borderRadius="md"
                                    width="100%"
                                    mb={4}
                                    position="relative"
                                >
                                    <AlertIcon />
                                    <Box flex="1" minWidth="0">
                                        <AlertTitle>Success!</AlertTitle>
                                        <AlertDescription whiteSpace="pre-wrap" wordBreak="break-word">
                                            {removeAccountSuccess}
                                        </AlertDescription>
                                    </Box>
                                    <CloseButton
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                        onClick={() => setRemoveAccountSuccess(null)}
                                    />
                                </Alert>
                            )}

                            {loadingAccounts ? (
                                <Flex justify="center" align="center" height="100px">
                                    <Spinner />
                                </Flex>
                            ) : addedAccounts.length === 0 ? (
                                <Text textAlign="center" color="gray.500">
                                    No accounts added to this application.
                                </Text>
                            ) : (
                                <VStack align="stretch" spacing={3}>
                                    {addedAccounts.map((acct) => (
                                        <Flex
                                            key={acct}
                                            justify="space-between"
                                            align="center"
                                            p={2}
                                            borderWidth="1px"
                                            borderRadius="md"
                                        >
                                            <Text>{acct}</Text>
                                            <Button
                                                size="sm"
                                                colorScheme="red"
                                                variant="outline"
                                                onClick={() => handleDeleteAccount(acct)}
                                            >
                                                Delete
                                            </Button>
                                        </Flex>
                                    ))}
                                </VStack>
                            )}
                        </Card>
                    )}

                    {/* Conditionally Render Delete Application Button */}
                    {showDeleteApplicationUI && (
                        <Card w="100%" p={4} variant="outline">
                            <Popover isOpen={isPopoverOpen}>
                                <PopoverTrigger>
                                    <Button
                                        colorScheme="red"
                                        w="100%"
                                        onClick={() => {
                                            setIsPopoverOpen(true);
                                            setIsAddAccountPopoverOpen(false);
                                        }}
                                    >
                                        Delete Application
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverHeader fontWeight="semibold">
                                        Confirmation
                                    </PopoverHeader>
                                    <PopoverBody>
                                        <VStack p={3} align="stretch">
                                            <Text fontWeight={500} textAlign="left">
                                                {`Are you sure you want to delete ${appState.selectedApplication?.name || 'this application'}?`}
                                            </Text>
                                        </VStack>
                                    </PopoverBody>
                                    <PopoverFooter display="flex" justifyContent="flex-end">
                                        <ButtonGroup size="sm">
                                            <Button variant="outline" onClick={() => setIsPopoverOpen(false)}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme="red" onClick={handleDeleteApplication}>
                                                Delete
                                            </Button>
                                        </ButtonGroup>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>
                        </Card>
                    )}
                </VStack>
            </VStack>
        </HStack>
    );
};

export default ApplicationSettings;
