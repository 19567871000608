// src/Components/EventBuilder.tsx
import React from 'react';
import {
    Button,
    Flex,
    HStack,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    VStack,
    Select,
    IconButton,
    Badge,
    Text,
    Grid,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

export interface EventSelection {
    eventName: string;
    valueKeys: string[];
    pendingValueKey: string;
}

interface EventBuilderProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    eventSelections: EventSelection[];
    setEventSelections: React.Dispatch<React.SetStateAction<EventSelection[]>>;
    defaultEventOptions: Record<string, string[]>;
}

const EventBuilder: React.FC<EventBuilderProps> = ({
    isOpen,
    onOpen,
    onClose,
    eventSelections,
    setEventSelections,
    defaultEventOptions,
}) => {
    const handleEventNameChange = (index: number, value: string) => {
        const updated = [...eventSelections];
        updated[index].eventName = value;
        updated[index].valueKeys = [];
        updated[index].pendingValueKey = '';
        setEventSelections(updated);
    };

    const handlePendingValueKeyChange = (index: number, value: string) => {
        const updated = [...eventSelections];
        updated[index].pendingValueKey = value;
        setEventSelections(updated);
    };

    const handleAddValueKey = (index: number) => {
        const updated = [...eventSelections];
        const pending = updated[index].pendingValueKey;
        if (pending && !updated[index].valueKeys.includes(pending)) {
            updated[index].valueKeys.push(pending);
        }
        updated[index].pendingValueKey = '';
        setEventSelections(updated);
    };

    const removeValueKey = (index: number, keyToRemove: string) => {
        const updated = [...eventSelections];
        updated[index].valueKeys = updated[index].valueKeys.filter((k) => k !== keyToRemove);
        setEventSelections(updated);
    };

    const removeEvent = (index: number) => {
        const updated = eventSelections.filter((_, i) => i !== index);
        setEventSelections(updated);
    };

    const handleAddEvent = () => {
        if (eventSelections.length < 5) {
            setEventSelections([...eventSelections, { eventName: '', valueKeys: [], pendingValueKey: '' }]);
        }
    };

    return (
        <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start" closeOnBlur>
            <PopoverAnchor>
                <Button variant="outline" onClick={onOpen}>
                    Events{eventSelections.length > 0 ? ` (${eventSelections.length})` : ''}
                </Button>
            </PopoverAnchor>
            <PopoverContent minW="400px" maxW="90vw" maxH="80vh" overflowY="auto">
                <PopoverCloseButton />
                <PopoverHeader>Select Events</PopoverHeader>
                <PopoverBody>
                    <Grid gap={3}>
                        {eventSelections.map((eventSel, index) => (
                            <Flex key={index} direction="column" p={2} borderWidth="1px" borderRadius="md">
                                <Flex justifyContent="space-between">
                                    <Select
                                        placeholder="Select Event"
                                        value={eventSel.eventName}
                                        onChange={(e) => handleEventNameChange(index, e.target.value)}
                                        size="sm"
                                        width="200px"
                                    >
                                        {Object.keys(defaultEventOptions).map((ev) => (
                                            <option key={ev} value={ev}>
                                                {ev}
                                            </option>
                                        ))}
                                    </Select>
                                    <IconButton
                                        aria-label="Remove event"
                                        icon={<CloseIcon />}
                                        size="sm"
                                        onClick={() => removeEvent(index)}
                                    />
                                </Flex>
                                {eventSel.eventName && (
                                    <Flex direction="column" mt={2} w='100%'>
                                        {/* Only show the Add Value options if there are available value keys */}
                                        {(defaultEventOptions[eventSel.eventName] || []).length > 0 && (
                                            <Flex alignItems="center" gap={2} w='100%' justify={'space-between'}>
                                                <Select
                                                    placeholder="Value Key (optional)"
                                                    size="sm"
                                                    value={eventSel.pendingValueKey}
                                                    onChange={(e) => handlePendingValueKeyChange(index, e.target.value)}
                                                    width="200px"
                                                >
                                                    {(defaultEventOptions[eventSel.eventName] || []).map((val) => (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <Button size="sm" onClick={() => handleAddValueKey(index)}>
                                                    Add Value
                                                </Button>
                                            </Flex>
                                        )}
                                        <HStack spacing={2} mt={2}>
                                            {eventSel.valueKeys.map((vk, idx) => (
                                                <Badge key={idx} colorScheme="blue">
                                                    <Flex alignItems="center">
                                                        <Text mr={1}>{vk}</Text>
                                                        <IconButton
                                                            aria-label="Remove value"
                                                            icon={<CloseIcon />}
                                                            size="xs"
                                                            variant="ghost"
                                                            onClick={() => removeValueKey(index, vk)}
                                                        />
                                                    </Flex>
                                                </Badge>
                                            ))}
                                        </HStack>
                                        <Text fontSize="xs" color="gray.500" mt={1}>
                                            Leave blank to count the event itself.
                                        </Text>
                                    </Flex>
                                )}
                            </Flex>
                        ))}
                        <Button size="sm" onClick={handleAddEvent} disabled={eventSelections.length >= 5}>
                            Add Event
                        </Button>
                    </Grid>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default EventBuilder;
