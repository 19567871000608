// AnalyticsOverview.tsx

import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    BoxProps,
    Button,
    Card,
    Center,
    Divider,
    Flex,
    HStack,
    Menu,
    Spinner,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    VStack,
    Wrap,
    useDisclosure,
    keyframes,
} from '@chakra-ui/react';
import { RangeDatepicker } from '../Components/chakra-dayzed-datepicker/src/index';
import {
    AppStateContext,
    OverviewData,
    MetadataResponse,
    RetentionMetric,
} from '../Contexts/AppStateContext';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from 'recharts';
import AnalyticsFilters from './AnalyticsFilters';

const CustomRetentionTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    padding: '10px',
                }}
            >
                <p style={{ margin: 0, color: 'black' }}>{label}</p>
                {payload.map((entry: any, index: number) => {
                    if (
                        entry.name === 'Retained Users' &&
                        entry.payload &&
                        typeof entry.payload.percentage === 'number'
                    ) {
                        return (
                            <p key={`item-${index}`} style={{ color: entry.stroke, margin: 0 }}>
                                Retained: {entry.value} ({entry.payload.percentage.toFixed(2)}%)
                            </p>
                        );
                    }
                    return (
                        <p key={`item-${index}`} style={{ color: entry.stroke, margin: 0 }}>
                            {entry.name}: {entry.value}
                        </p>
                    );
                })}
            </div>
        );
    }
    return null;
};

const pulseDot = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(0.5); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
`;

// Use UTC when parsing dates from "YYYY-MM-DD" strings.
function parseLocalDate(dateStr: string): Date {
    const [y, m, d] = dateStr.split('-').map(Number);
    return new Date(Date.UTC(y, m - 1, d));
}

// Format a date in UTC as "YYYY-MM-DD"
function formatDateLocal(date: Date): string {
    return date.toISOString().slice(0, 10);
}

function getAllDatesInRange(start?: Date, end?: Date): string[] {
    if (!start || !end) return [];
    // Set both start and end to local midnight
    const sMid = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const eMid = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    const dates: string[] = [];
    const cur = new Date(sMid);
    while (cur <= eMid) {
        dates.push(formatDateLocal(cur));
        cur.setDate(cur.getDate() + 1);
    }
    return dates;
}

const AnalyticsOverview = () => {
    const appState = useContext(AppStateContext);

    // Default range: 3 days ago -> UTC midnight of tomorrow minus 1ms
    const [range, setRange] = useState<Date[]>(() => {
        const today = new Date();
        const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3);
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        const endDate = new Date(tomorrow.getTime() - 1);
        return [startDate, endDate];
    });

    const [pendingRange, setPendingRange] = useState<Date[]>(range);
    const [overviewData, setOverviewData] = useState<OverviewData>();
    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [metadataResponse, setMetadataResponse] = useState<MetadataResponse | null>(null);
    const [fetchingMetadata, setFetchingMetadata] = useState<boolean>(false);

    const [filterState, setFilterState] = useState<Record<string, (string | number)[]>>({
        buildGUID: [],
        deviceModel: [],
        eventData: [],
        graphicsDeviceName: [],
        graphicsDeviceType: [],
        graphicsMemorySizeMB: [],
        identifier: [],
        installMode: [],
        installerName: [],
        operatingSystem: [],
        operatingSystemFamily: [],
        processorType: [],
        sandboxType: [],
        systemLanguage: [],
        systemMemorySizeMB: [],
        version: [],
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    // When the selected app changes, fetch data.
    useEffect(() => {
        if (appState.selectedApplication?.service_key) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.selectedApplication?.service_key]);

    // When a new valid date range is finalized, refresh metadata (and update filters accordingly)
    useEffect(() => {
        if (pendingRange.length === 2) {
            fetchMetadataOnly();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingRange]);

    function handleDateChange(dates: Date[]) {
        if (dates.length === 2) {
            const [start, end] = dates;
            // Use local getters for the start date.
            const newStart = new Date(start.getFullYear(), start.getMonth(), start.getDate());
            const endNextMidnight = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
            const newEnd = new Date(endNextMidnight.getTime() - 1);
            setPendingRange([newStart, newEnd]);
        } else {
            setPendingRange(dates);
        }
    }

    /** Called when the user presses the Refresh button.  
     * This refreshes both overview and metadata. */
    async function fetchData() {
        if (fetchingData || pendingRange.length < 2) return;
        setRange(pendingRange);
        setFetchingData(true);
        try {
            const serviceKey = appState.selectedApplication?.service_key || '';
            const [start, end] = pendingRange;
            const startISO = start.toISOString();
            const endISO = end.toISOString();
            // 1) Fetch overview data
            const dataResult = await appState.getOverviewData(serviceKey, startISO, endISO, filterState);
            if (dataResult) {
                setOverviewData(dataResult);
            }
            // 2) Fetch metadata
            const metadataResult = await appState.getMetadata(serviceKey, startISO, endISO);
            setMetadataResponse(metadataResult);
            // 3) Merge new metadata keys into filter state without losing existing valid selections.
            if (metadataResult) {
                setFilterState((prev) => {
                    const newFilterState = { ...prev };
                    if (!newFilterState.hasOwnProperty('eventData')) {
                        newFilterState['eventData'] = [];
                    }
                    Object.keys(metadataResult.metadata).forEach((key) => {
                        if (!newFilterState.hasOwnProperty(key)) {
                            newFilterState[key] = [];
                        } else {
                            // Retain only selections that are still available.
                            newFilterState[key] = newFilterState[key].filter((val) =>
                                metadataResult.metadata[key].includes(val)
                            );
                        }
                    });
                    return newFilterState;
                });
            }
            setFetchingData(false);
            onClose();
        } catch (err) {
            console.error(err);
            setFetchingData(false);
            onClose();
        }
    }

    /** Fetch only metadata (and update filter state) for the new date range. */
    async function fetchMetadataOnly() {
        if (pendingRange.length < 2) return;
        setFetchingMetadata(true);
        try {
            const serviceKey = appState.selectedApplication?.service_key || '';
            const [start, end] = pendingRange;
            const startISO = start.toISOString();
            const endISO = end.toISOString();
            const metadataResult = await appState.getMetadata(serviceKey, startISO, endISO);
            setMetadataResponse(metadataResult);
            if (metadataResult) {
                setFilterState((prev) => {
                    const newFilterState = { ...prev };
                    if (!newFilterState.hasOwnProperty('eventData')) {
                        newFilterState['eventData'] = [];
                    }
                    Object.keys(metadataResult.metadata).forEach((key) => {
                        if (!newFilterState.hasOwnProperty(key)) {
                            newFilterState[key] = [];
                        } else {
                            newFilterState[key] = newFilterState[key].filter((val) =>
                                metadataResult.metadata[key].includes(val)
                            );
                        }
                    });
                    return newFilterState;
                });
            }
            setFetchingMetadata(false);
        } catch (err) {
            console.error(err);
            setFetchingMetadata(false);
        }
    }

    function parseAndAggregateDaily() {
        if (!overviewData?.daily_metrics || range.length < 2) return [];
        interface DailyAggregate {
            ActivityDate: string;
            UniqueUsers: number;
            NewUsers: number;
            Sessions: number;
            MedianSessionTime: number;
        }

        const allAcitivityDates = overviewData.daily_metrics.map((item) => item.ActivityDate);
        const parsedActivityDates = allAcitivityDates.map((dateStr) => parseLocalDate(dateStr));
        const allDates = getAllDatesInRange(range[0], range[1]);
        //check if allDates contains all the dates in parsedActivityDates
        if (!(parsedActivityDates.every((date) => allDates.includes(formatDateLocal(date))))) {
            //add missing dates to allDates
            const missingDates = parsedActivityDates
                .map((date) => formatDateLocal(date))
                .filter((date) => !allDates.includes(date));
            allDates.push(...missingDates);
            allDates.sort();
        }

        const metricMap = new Map<string, DailyAggregate>();
        for (const item of overviewData.daily_metrics) {
            const localDate = parseLocalDate(item.ActivityDate);
            const dateKey = formatDateLocal(localDate);
            if (!metricMap.has(dateKey)) {
                metricMap.set(dateKey, {
                    ActivityDate: dateKey,
                    UniqueUsers: item.UniqueUsers || 0,
                    NewUsers: item.DailyNewUsers || 0,
                    Sessions: item.Sessions || 0,
                    MedianSessionTime: item.MedianSessionTime || 0,
                });
            } else {
                const agg = metricMap.get(dateKey)!;
                agg.UniqueUsers += item.UniqueUsers || 0;
                agg.NewUsers += item.DailyNewUsers || 0;
                agg.Sessions += item.Sessions || 0;
                if (item.MedianSessionTime > agg.MedianSessionTime) {
                    agg.MedianSessionTime = item.MedianSessionTime;
                }
            }
        }
        return allDates.map((dStr) =>
            metricMap.has(dStr)
                ? metricMap.get(dStr)!
                : {
                    ActivityDate: dStr,
                    UniqueUsers: 0,
                    NewUsers: 0,
                    Sessions: 0,
                    MedianSessionTime: 0,
                }
        );
    }

    function getDAUChartData() {
        return parseAndAggregateDaily().map((agg) => ({
            name: agg.ActivityDate,
            uv: agg.UniqueUsers,
        }));
    }
    function getSessionChartData() {
        return parseAndAggregateDaily().map((agg) => ({
            name: agg.ActivityDate,
            uv: agg.Sessions,
        }));
    }
    function getMedianSessionLengthChartData() {
        return parseAndAggregateDaily().map((agg) => ({
            name: agg.ActivityDate,
            uv: agg.MedianSessionTime,
        }));
    }
    function getNewUsersChartData() {
        return parseAndAggregateDaily().map((agg) => ({
            name: agg.ActivityDate,
            uv: agg.NewUsers,
        }));
    }

    function getFullRetentionData() {
        const raw = overviewData?.retention_metrics;
        if (!raw) return [];
        const map = new Map<number, { retained: number; cohort: number }>();
        raw.forEach((r: RetentionMetric) => {
            if (!map.has(r.DayOffset)) {
                map.set(r.DayOffset, { retained: 0, cohort: 0 });
            }
            const obj = map.get(r.DayOffset)!;
            obj.retained += r.RetainedUsers;
            obj.cohort += r.CohortSize;
        });
        const arr = [];
        for (let i = 0; i < 10; i++) {
            if (map.has(i)) {
                const { retained, cohort } = map.get(i)!;
                const percentage = cohort ? (retained / cohort) * 100 : 0;
                arr.push({
                    name: `Day ${i}`,
                    retained,
                    cohort,
                    percentage,
                });
            } else {
                arr.push({
                    name: `Day ${i}`,
                    retained: 0,
                    cohort: 0,
                    percentage: 0,
                });
            }
        }
        return arr;
    }
    function getRetentionCombinedChartData() {
        return getFullRetentionData();
    }
    function getDay1Retention() {
        // Find the Day 1 retention rate by finding all dayoffset=1 entries and summing their retained users.
        const raw = overviewData?.retention_metrics;
        if (!raw) return 0;
        const day1 = raw.filter((r) => r.DayOffset === 1);
        const totalRetained = day1.reduce((acc, cur) => acc + cur.RetainedUsers, 0);
        const totalCohort = day1.reduce((acc, cur) => acc + cur.CohortSize, 0);
        return totalCohort ? (totalRetained / totalCohort) * 100 : 0;
    }

    function formatTime(sec: number) {
        sec = Math.round(sec);
        if (sec < 60) return `${sec} sec`;
        const h = Math.floor(sec / 3600);
        const m = Math.floor((sec % 3600) / 60);
        const s = sec % 60;
        let str = '';
        if (h) str += `${h} hr `;
        if (m) str += `${m} min `;
        if (s || (!h && !m)) str += `${s} sec`;
        return str.trim();
    }

    function getTopStuff() {
        return (
            <Flex
                maxH="calc(100vh - 100px)"
                w="100%"
                direction="row"
                justifyContent="space-between"
                pl={5}
                pr={2}
                pb={3}
                mt={0}
            >
                <Center pt={1}>
                    <Text fontWeight={500} w="100%" textAlign="left">
                        Analytics Overview
                    </Text>
                </Center>
                <Center pt={1}>
                    <Flex gap={2} direction="row">
                        <Menu>
                            <RangeDatepicker
                                selectedDates={pendingRange}
                                onDateChange={handleDateChange}
                                maxDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                            />
                        </Menu>
                        {/* Swapped: Filters now appears before Refresh */}
                        <AnalyticsFilters
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                            metadataResponse={metadataResponse}
                            filterState={filterState}
                            setFilterState={setFilterState}
                            metadataLoading={fetchingMetadata}
                        />
                        <Button
                            variant="outline"
                            onClick={fetchData}
                            isLoading={fetchingData}
                            loadingText="Refreshing"
                            disabled={!appState.selectedApplication?.service_key || fetchingData}
                        >
                            Refresh
                        </Button>
                    </Flex>
                </Center>
            </Flex>
        );
    }

    const outerContainerStyle: BoxProps = {
        w: '100%',
        h: 'calc(100vh - 100px)',
        p: 3,
        boxSizing: 'border-box',
    };

    return (
        <Flex direction="column" {...outerContainerStyle}>
            {getTopStuff()}
            <Divider mb={2} />
            <Box flex="1" overflowY="auto">
                {fetchingData ? (
                    <Flex w="100%" h="100%" direction="column" justifyContent="center" alignItems="center">
                        <Spinner size="xl" />
                    </Flex>
                ) : !overviewData || !overviewData.daily_metrics || range.length < 2 ? (
                    <Flex w="100%" h="100%" direction="column" justifyContent="center" alignItems="center">
                        <Text fontSize="lg" color="gray.500">
                            No data available!
                        </Text>
                    </Flex>
                ) : (
                    <Wrap w="100%" alignSelf="flex-start" mt={2} p={0} overflowY="auto">
                        <VStack w="100%" justifyContent="space-between">
                            {/* Stat Cards */}
                            <HStack w="100%" justifyContent="space-between" spacing={4}>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel display="flex" alignItems="center" gap={2}>
                                            Concurrent (Live)
                                            <Box
                                                as="span"
                                                mt={0.65}
                                                w="8px"
                                                h="8px"
                                                bg="red.500"
                                                borderRadius="full"
                                                animation={`${pulseDot} 1.2s ease-in-out infinite`}
                                            />
                                        </StatLabel>
                                        <StatNumber>{overviewData.concurrent_live}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Peak Concurrent</StatLabel>
                                        <StatNumber>{overviewData.peak_concurrent}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Peak DAU</StatLabel>
                                        <StatNumber>{overviewData.peak_dau}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>New Users</StatLabel>
                                        <StatNumber>{overviewData.overall_new_users}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Unique Users</StatLabel>
                                        <StatNumber>{overviewData.unique_users}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Total Sessions</StatLabel>
                                        <StatNumber>{overviewData.total_sessions}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Sessions Per User</StatLabel>
                                        <StatNumber>{overviewData.sessions_per_user.toFixed(2)}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Day 1 Retention</StatLabel>
                                        <StatNumber>
                                            {(
                                                getDay1Retention() || 0
                                            ).toFixed(2)}
                                            %
                                        </StatNumber>
                                    </Stat>
                                </Card>
                            </HStack>

                            <HStack w="100%" mt={1} justifyContent="space-between" spacing={4}>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Total Time Spent</StatLabel>
                                        <StatNumber>{formatTime(overviewData.total_time_spent)}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Md. Session Time</StatLabel>
                                        <StatNumber>{formatTime(overviewData.median_session_time)}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Md. Time Per User Per Day</StatLabel>
                                        <StatNumber>{formatTime(overviewData.median_time_per_day)}</StatNumber>
                                    </Stat>
                                </Card>
                                <Card p={3} w="100%" h="100%">
                                    <Stat>
                                        <StatLabel>Md. Time Per User</StatLabel>
                                        <StatNumber>{formatTime(overviewData.median_time_per_user)}</StatNumber>
                                    </Stat>
                                </Card>
                            </HStack>

                            <Divider mt={2} mb={1} />

                            {/* Daily Charts */}
                            <HStack w="100%" pr={2} justifyContent="space-between">
                                <Box w="100%">
                                    <Text align="center" fontWeight={400} w="100%">
                                        Unique Users
                                    </Text>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={getDAUChartData()}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <RechartsTooltip contentStyle={{ color: 'black' }} />
                                            <Line name="Unique Users" type="monotone" dataKey="uv" stroke="#82ca9d" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                                <Box w="100%">
                                    <Text align="center" fontWeight={400} w="100%">
                                        Sessions
                                    </Text>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={getSessionChartData()}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <RechartsTooltip contentStyle={{ color: 'black' }} />
                                            <Line name="Sessions" type="monotone" dataKey="uv" stroke="#82ca9d" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                                <Box w="100%">
                                    <Text align="center" fontWeight={400} w="100%">
                                        Median Session Length
                                    </Text>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={getMedianSessionLengthChartData()}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <RechartsTooltip
                                                contentStyle={{ color: 'black' }}
                                                formatter={(value) => [formatTime(Number(value)), 'Duration']}
                                            />
                                            <Line name="Session Length" type="monotone" dataKey="uv" stroke="#82ca9d" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                            </HStack>

                            <Divider />

                            {/* Retention & New Users Charts */}
                            <HStack w="100%" pr={2} justifyContent="space-between">
                                <Box w="100%">
                                    <Text align="center" fontWeight={400} w="100%">
                                        New Users Per Day
                                    </Text>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <LineChart data={getNewUsersChartData()}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <RechartsTooltip contentStyle={{ color: 'black' }} />
                                            <Line name="New Users" type="monotone" dataKey="uv" stroke="#82ca9d" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                                <Box w="100%">
                                    <Text align="center" fontWeight={400} w="100%">
                                        Retention Numbers
                                    </Text>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <LineChart data={getRetentionCombinedChartData()}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <RechartsTooltip content={<CustomRetentionTooltip />} />
                                            <Line name="Retained Users" type="monotone" dataKey="retained" stroke="#82ca9d" />
                                            <Line name="Cohort Size" type="monotone" dataKey="cohort" stroke="#8884d8" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                            </HStack>
                        </VStack>
                    </Wrap>
                )}
            </Box>
        </Flex>
    );
};

export default AnalyticsOverview;
